import React, { useState } from 'react';
import JobRoleSelect from '../component/form/JobSelect';

const CreateProfile = () => {
  const [selectedJobRole, setSelectedJobRole] = useState(null);

  const handleJobRoleChange = (selectedOption) => {
    setSelectedJobRole(selectedOption);
    console.log('Selected job role:', selectedOption);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission
    console.log('Profile created with job role:', selectedJobRole);
  };

  return (
    <div>
      <h2>Create Profile</h2>
      <form onSubmit={handleSubmit}>
        {/* Other form fields */}

        <div className="form-group">
          <label>Job Role</label>
          <JobRoleSelect onChange={handleJobRoleChange} />
        </div>

        <button type="submit">Create Profile</button>
      </form>
    </div>
  );
};

export default CreateProfile;
